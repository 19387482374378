import { render, staticRenderFns } from "./ImageBannerHero.vue?vue&type=template&id=177ed9b1&"
import script from "./ImageBannerHero.vue?vue&type=script&lang=js&"
export * from "./ImageBannerHero.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OpusTitle: require('/~/src/modules/opus/components/Title.vue').default,ContentfulRichText: require('/~/src/modules/contentful/components/RichText.vue').default,OpusButton: require('/~/src/modules/opus/components/Form/Button.vue').default,ContentfulVideo: require('/~/src/modules/contentful/components/ContentfulVideo.vue').default})
